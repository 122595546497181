let $flyout = $('section.flyout'),
    $flyoutThumbnails = $('.flyout .flyout__thumbnails'),
    cartURL = $('.flyout').data('cart-url');

let successIcon;

$(".add-to-cart-messages").on('click', () => {
    $(".add-to-basket-alert").remove();
    $(".add-to-cart-messages").remove();
});

const [productEmptyTemplate] = $('.flyout__thumbnails .thumbnail--empty');

const buildButtonsTemplate = (count) => {
    let className = '.flyout__buttons .flyout__buttons--';

    (count == 0) ?
        className += 'first' : null;

    (count > 0 && count < 3) ?
        className += 'while' : null;

    (count == 3) ?
        className += 'complete' : null;

    $(className).removeClass('hidden')
    $(className).siblings().addClass('hidden');
};

const loadFlyOutEvents = function() {

    $flyout.on('flyout:updateCount', function(event, count) {
        const $this = $(this);
        if (typeof(count) !== 'number') return;

        // hide all alerts
        $('.flyout__alert').addClass('hidden');

        buildButtonsTemplate(count);

        $this.attr('data-count', count);

        (count == 0) ?
            $('.flyout__alert--empty').removeClass('hidden') : null;

        (count == 1) ?
            $('.flyout__alert--one').removeClass('hidden') : null;

        (count == 2) ?
            $('.flyout__alert--two').removeClass('hidden') : null;

        (count == 3) ?
            $('.flyout__alert--three').removeClass('hidden') : null;
    });

    $flyout.on('flyout:open', function() {
        $(this).removeClass('flyout--hidden');
    });

    $flyout.on('flyout:cloase', function() {
        $(this).addClass('flyout--hidden');
    });

    $flyout.on('flyout:deleteItem', function(event, item){
        const {
            id, UUID,
            quantity,
        } = item;

        const controllerURL = quantity > 1 ?
            `${cartURL}-UpdateQuantity?pid=${id}&uuid=${UUID}&quantity=${parseInt(quantity)-1}` :
            `${cartURL}-RemoveProductLineItem?pid=${id}&uuid=${UUID}`;

        fetch(controllerURL)
            .then(res => res.json())
            .then(data => {
                if (data){
                    let bodyTriggers = quantity == 1 ?
                    ({
                        quantityTotal: data.basket.numItems,
                        cart: data.basket
                    }):
                    ({
                        quantityTotal: data.numItems,
                        cart: data
                    });

                    $('.minicart').trigger("count:update", bodyTriggers)
                    $flyout.trigger('flyout:updateItems', bodyTriggers);
                }
            });

    });

    $flyout.on('flyout:finishPack', function() {
        const packId = $flyout.attr('data-packid')
        const getProductsInLStorage = localStorage.getItem('packs_list');
        const localProducts = JSON.parse(getProductsInLStorage);
        let packUpdate = localProducts.find(pack => pack.packId == Number(packId))
        packUpdate.finish = true;
        const newArr = [
            ...localProducts.filter(pack => pack.packId != Number(packId)),
            packUpdate
        ]
        localStorage.setItem('packs_list', JSON.stringify(newArr));
    });

    $('.btn-finish').on('click', function(e) {
        $flyout.trigger('flyout:finishPack');

        $(".add-to-basket-alert").remove();
        $(".add-to-cart-messages").remove();

        $('.flyout').trigger('flyout:close');
        (0 === $(".add-to-cart-messages").length && $("body").append('<div class="add-to-cart-messages"></div>'),
        $(".add-to-cart-messages").append('<div class="alert alert-success add-to-basket-alert text-center" role="alert">¡Tu paquete se ha agregó al carrito de compra!</div>"'),
        $(".add-to-basket-alert").prepend(`<img src="${successIcon}" />`),
        setTimeout((function() {
            $(".add-to-basket-alert").remove();
            $(".add-to-cart-messages").remove();
        }), 5e3));
        //$flyout.trigger('flyout:finishPack');
    });

    $('.btn-go-pay').on('click', () => {
        setTimeout(() => location.href = cartURL + "-Show", 3000);
    })

    $('.btn-go-buy').on('click', () => {
        setTimeout(() => location.href = "/s/MensFashion/3x1799", 3000);
    })

    $flyout.on('flyout:updateItems', function(event, info){
        //console.log("flyout-event", {event, info});
        const $this = $(this);

        // set product list
        let list = [];
        if (info.cart && info.cart.items) list = [...info.cart.items]
            .filter(item => item.promos_flyout);

        const productList = list.flatMap(product =>
            Array.from({ length: product.quantity }, () => ({ ...product }))
        );

        let currentPack = [];
        function groupPacks(arr, arrLen = 3) {
            const getProductsInLStorage = localStorage.getItem('packs_list');

            const subarrays = [];
            let packId = 1;
            for (let i = 0; i < arr.length; i += arrLen) {
                let subPackItem = arr.slice(i, i + arrLen);
                let packInfo = {
                    packId,
                    items: subPackItem
                }
                let currentPackId;
                if (!getProductsInLStorage){
                    subPackItem.length == 3 ? packInfo.finish = true : null;
                    subarrays.push(packInfo);
                } else {
                    const getList = JSON.parse(getProductsInLStorage);
                    let packInfo2 = getList.find(pack => pack.packId == packId);

                    let finish = packInfo2 && packInfo2.finish == true && packInfo.items.length == 3 ? true : false;

                    const hasNextPack = arr[i+arrLen]

                    if(hasNextPack) finish = true;

                    subarrays.push(
                        {
                            ...packInfo,
                            finish
                        }
                    );

                    if (subarrays.length > 1) {
                        $('.flyout__package--name').html(`paquete (#${subarrays.length})`)
                    }

                    const getFirstUnfinished = getList.find(pack => !pack.finish);
                    currentPackId = getFirstUnfinished && getFirstUnfinished.packId ? getFirstUnfinished.packId : subarrays.length;

                }

                $flyout.attr('data-packid', currentPackId);

                packId++;

            }
            localStorage.setItem('packs_list', JSON.stringify(subarrays))
            return subarrays;
        }

        //console.log(currentPack);
        function getCurrentPack() {
            const packList = localStorage.getItem('packs_list');

            if(packList) {
                const packListJSON = JSON.parse(packList);
                const foundPack = packListJSON.find(pack => !pack.finish);
                return foundPack ? foundPack.items : [];
            }
        }

        groupPacks(productList);

        currentPack = getCurrentPack();

        // update count for display alert
        $this.trigger('flyout:updateCount', currentPack.length);

        // render product list ui
        if (productList) {
            $flyoutThumbnails.empty();
            const emptyStates = 3 - currentPack.length;

            currentPack.map(item => {
                const {
                    brand, productName,
                    images,
                    promos_flyout
                 } = item;

                //if (!promos_flyout.length) return;
                const [ image ] = images.small;

                //console.log('productList length:', productList.length);
                const deleteButton = `
                    <a href="#" class="delete--product">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.41 6.99994L12.71 2.70994C12.8983 2.52164 13.0041 2.26624 13.0041 1.99994C13.0041 1.73364 12.8983 1.47825 12.71 1.28994C12.5217 1.10164 12.2663 0.99585 12 0.99585C11.7337 0.99585 11.4783 1.10164 11.29 1.28994L7 5.58994L2.71 1.28994C2.5217 1.10164 2.2663 0.99585 2 0.99585C1.7337 0.99585 1.4783 1.10164 1.29 1.28994C1.1017 1.47825 0.995908 1.73364 0.995908 1.99994C0.995908 2.26624 1.1017 2.52164 1.29 2.70994L5.59 6.99994L1.29 11.2899C1.19627 11.3829 1.12188 11.4935 1.07111 11.6154C1.02034 11.7372 0.994202 11.8679 0.994202 11.9999C0.994202 12.132 1.02034 12.2627 1.07111 12.3845C1.12188 12.5064 1.19627 12.617 1.29 12.7099C1.38296 12.8037 1.49356 12.8781 1.61542 12.9288C1.73728 12.9796 1.86799 13.0057 2 13.0057C2.13201 13.0057 2.26272 12.9796 2.38458 12.9288C2.50644 12.8781 2.61704 12.8037 2.71 12.7099L7 8.40994L11.29 12.7099C11.383 12.8037 11.4936 12.8781 11.6154 12.9288C11.7373 12.9796 11.868 13.0057 12 13.0057C12.132 13.0057 12.2627 12.9796 12.3846 12.9288C12.5064 12.8781 12.617 12.8037 12.71 12.7099C12.8037 12.617 12.8781 12.5064 12.9289 12.3845C12.9797 12.2627 13.0058 12.132 13.0058 11.9999C13.0058 11.8679 12.9797 11.7372 12.9289 11.6154C12.8781 11.4935 12.8037 11.3829 12.71 11.2899L8.41 6.99994Z" fill="black"/>
                        </svg>
                    </a>
                `;

                let template = $(productEmptyTemplate).clone();
                $(template).removeClass('thumbnail--empty');
                $(template).addClass('thumbnail--product');
                $(template).find('.flyout__plusIcon').remove();

                $(template).append(deleteButton);
                $(template).find('.delete--product').on('click', (e) => {
                    //if(confirm('¿Eliminar?')){
                        e.preventDefault();
                        $flyout.trigger('flyout:deleteItem', item);
                    //}
                })

                if (image) {
                    $(template).find('.flyout__product').attr('src', image.url);
                }

                $(template).find('.flyout__product').attr('alt', `${brand} - ${productName}`);

                $flyoutThumbnails.append(template);
            })

            for (let i = 0; i < emptyStates; i++){
                $flyoutThumbnails.append($(productEmptyTemplate).clone());
            }

        }
    })
}

$(document).ready(function() {
    loadFlyOutEvents();

    $('.flyout > header').on('click', () => {
        const $this = $('.flyout'), className = 'flyout--hidden';
        $this.hasClass(className) ?
            $this.removeClass(className) :
            $this.addClass(className);
    });

    const fetchData = async () => {
        const url = $('.flyout').data('endpoint')
        if (url){
            await fetch(`${url}-List`)
                .then(response => response.json())
                .then(data => {
                    ////console.log(data);
                    ////console.log(data.cart.items);
                    if (data && data.cart){
                        successIcon = data.success;
                        $flyout.trigger("flyout:updateItems", data);
                    }
                })
                .catch(e => console.log(e))
        }

    }

    fetchData();
});
